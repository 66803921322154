import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Elsdorf: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Elsdorf,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <polygon
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Elsdorf"
          points="897.3,897.8 902,926 897.2,942 878,942 874,932 862,932 860,940 840,938 824.5,921.1 830,912 828.6,907.8 860,900 860,892 896,898  "
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 846.883 920.8245)"
        >
          Elsdorf
        </text>
      </g>
    </>
  );
});
